import Header from "../../../types/data/comtrade/header/header";

const parseHeaderContentsToHeader = (headerContents: string): Header => {
  // todo implement
  if (!headerContents) {
    return {};
  }
  return {};
};

export default parseHeaderContentsToHeader;
